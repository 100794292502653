import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, IconButton, Button, Typography, Divider, Link, Modal, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled, useTheme } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import SwiperCore from "swiper/core";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { getStoreDetail } from "../../Services/StoreService";
import NoImage from "../../assets/images/NoImage.webp";
import { convertEatinTakeoutToString, convertRecommendToString, format } from "../common/text";
import { getMarkerIcon } from "../common/map";

SwiperCore.use([Navigation, Pagination]);
SwiperCore.use([Autoplay]);

//スライド画像を作成
function SlideImage({ images }) {
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");
    const handleOpen = (image) => {
        setSelectedImage(image);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // 表示する画像を整理
    let showImage = images.slice();
    if (showImage.length <= 0) {
        // 画像がない場合はNoImageを表示
        showImage = [NoImage];
    }
    // サイズ取得
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const swiperStyle = {
        height: isMobile ? "200px" : "400px" // スマートフォンでは200px、それ以外では400px
    };
    return (
        <>
            <Swiper
                modules={[Navigation, Pagination, Autoplay]}
                navigation
                pagination={{ clickable: true }}
                loop={true}
                autoplay={{ delay: 3000 }}
                slidesPerView={1}
                style={swiperStyle}
            >
                {showImage.map((image, index) => (
                    <SwiperSlide key={index} style={{ height: "100%" }}>
                        <img src={image} alt={`店舗写真 ${index + 1}`} style={{ width: "100%", height: "100%", objectFit: "contain", objectPosition: "center" }} onClick={() => handleOpen(image)} />
                    </SwiperSlide>
                ))}
            </Swiper>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="拡大画像"
                aria-describedby="画像をクリックするとここに拡大表示されます"
            >
                <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", bgcolor: "background.paper", boxShadow: 24, p: 2 }}>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{ position: "absolute", right: 16, top: 16, color: "white" }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <img src={selectedImage} alt="拡大画像" style={{ maxWidth: "90vw", maxHeight: "90vh" }} />
                </Box>
            </Modal>
        </>
    );
}

// ダイアログタイトル
function DitailTitle ({ detailInfo, onClose }) {
    return (
        <DialogTitle>
            {detailInfo.name}
            <IconButton
                edge="end"
                color="inherit"
                onClick={onClose}
                aria-label="close"
                sx={{ position: "absolute", right: 24, top: 12 }}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
    )
}

// ダイアログコンテンツ
function DitailContents ({ detailInfo, onClose }) {
    // マップのスタイル
    const containerStyle = {
        width: "100%",
        height: "400px",
    };

    // 吹き出しのタイトルのスタイル
    const OpenMap = styled("div")({
        marginBottom: "8px",
        color: "blue",
        textDecoration: "underline",
        cursor: "pointer"
    });

    return (
        <DialogContent dividers>
            <SlideImage images={detailInfo.images} />
            {/* <div style={{ textAlign: "right" }}>
                <Button variant="text" size="small" color="primary" onClick={copyToClipboard}>お店情報をコピー</Button>
            </div> */}
            <Divider variant="fullWidth" sx={{ my: 1 }} />
            <Typography variant="subtitle1" gutterBottom>
                住所：{detailInfo.address}
            </Typography>
            <Divider variant="fullWidth" sx={{ my: 1 }} />
            <Typography variant="subtitle1" gutterBottom>
                カテゴリ：{detailInfo.category}
            </Typography>
            <Divider variant="fullWidth" sx={{ my: 1 }} />
            <Typography variant="subtitle1" gutterBottom>
                おすすめ商品：{detailInfo.product}
            </Typography>
            <Divider variant="fullWidth" sx={{ my: 1 }} />
            <Typography variant="subtitle1" gutterBottom>
                おすすめ度：{convertRecommendToString(detailInfo.recommend)}
            </Typography>
            <Divider variant="fullWidth" sx={{ my: 1 }} />
            <Typography variant="subtitle1" gutterBottom>
                イートイン・テイクアウト：{convertEatinTakeoutToString(detailInfo.eatin_takeout)}
            </Typography>            
            <Divider variant="fullWidth" sx={{ my: 1 }} />
            <Typography variant="subtitle1" gutterBottom>
                ホームページ：<Link href={detailInfo.url} target="_blank" rel="noopener noreferrer" style={{ wordWrap: "break-word" }}>
                {detailInfo.url}
                </Link>
            </Typography>
            <Divider variant="fullWidth" sx={{ my: 1 }} />
            <Typography variant="subtitle1" gutterBottom>
                コメント：
                {detailInfo.comment 
                    ? <>
                        <br />
                        {detailInfo.comment.split("\n").map((line, index) => (
                            <span key={index}>
                                {line}
                                <br />
                            </span>
                        ))}
                    </>
                    : null
                }
            </Typography>
            <Divider variant="fullWidth" sx={{ my: 1 }} />
            <div id="map">
                {/* マップクリックで緯度経度からGoogleマップを表示 */}
                {/* <OpenMap onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${center.lat},${center.lng}`, "_blank")}> */}
                {/* マップクリックで名前と住所からGoogleマップを表示 */}
                <OpenMap onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(detailInfo.name + ", " + detailInfo.address)}`, "_blank")}>
                    GoogleMapで開く
                </OpenMap>
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={{ lat: parseFloat(detailInfo.lat), lng: parseFloat(detailInfo.lng) }}
                    zoom={17}
                >
                    <Marker
                        position={{ lat: parseFloat(detailInfo.lat), lng: parseFloat(detailInfo.lng) }}
                        icon={{
                            url: getMarkerIcon(detailInfo.category),
                            scaledSize: new window.google.maps.Size(32, 32), 
                        }}
                    />
                </GoogleMap>
            </div>
            <div style={{ fontSize: 'small', textAlign: 'right' }}>
                {detailInfo.updated_at ? detailInfo.updated_at : "-"} 更新
            </div>
            <Divider variant="fullWidth" sx={{ my: 1 }} />
            <Button variant="contained" size="large" color="primary" onClick={onClose} sx={{ mt: 2, marginLeft: "auto", marginRight: "auto", display: "flex", width: 100 }}>閉じる</Button>
        </DialogContent>
    )
}

// メイン処理
function DetailScreen({ id, onClose }) {

    // 詳細情報取得用ステート
    const [detailInfo, setStoreDetail] = useState(null);
    const [loading, setLoading] = useState(true);

    // コンポーネントのマウント時に非同期データを取得
    useEffect(() => {
        const fetchStoreDetail = async () => {
            const data = await getStoreDetail(id);
            // 詳細情報更新
            setStoreDetail(data);
            setLoading(false);
        };    
        fetchStoreDetail();
    }, [id]);

    // ローディング中、エラー発生時、データがない場合の表示
    if (loading) {
        return <div>Loading...</div>;
    }
    if (!detailInfo) {
        return <div>No data available</div>;
    }

    return (
        <Dialog open={true} onClose={onClose} fullWidth maxWidth="md">
            <DitailTitle detailInfo={detailInfo} onClose={onClose} />
            <DitailContents detailInfo={detailInfo} onClose={onClose} />
        </Dialog>
    );
}

export default DetailScreen;
