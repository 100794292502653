import { get, set, update, del, setFormData } from "./api";

// マップ情報取得
const getMapInfo = async (keyword="", category="", area="", recommend="", eatin_takeout="") => {
    let mapInfo = [];
    try {
        mapInfo = await get("map", {"keyword": keyword, "category": category, "area": area, "recommend": recommend, "eatin_takeout":eatin_takeout});
    } catch (error) {
        console.error("Fetching failed", error);
    }
    return mapInfo;
};


// 1ページに表示する情報を取得
const getPageInfo = async (keyword="", category="", area="", recommend="", eatin_takeout="", page=1, par=5) => {
    let pageInfo = [];
    try {
        pageInfo = await get("pageInfo", {"keyword": keyword, "category": category, "area": area, "recommend": recommend, "eatin_takeout":eatin_takeout, "page":page, "par": par});
    } catch (error) {
        console.error("Fetching failed", error);
    }
    
    return pageInfo;
};



// お店の詳細情報を取得
const getStoreDetail = async (id) => {
    let detailInfo = [];
    try {
        detailInfo = await get("storeDetail", {"id":id});
    } catch (error) {
        console.error("Fetching failed", error);
    }
    return detailInfo;
};

// 店舗情報登録
const addStoreInfo = async (addData) => {
    let result = false;
    try {
        const response = await setFormData("storeInfo", addData);
        result = true;
    } catch (error) {
        console.error("Fetching failed", error);
    }
    return result;
};

// 店舗情報更新
const getStoreList = async (keyword) => {
    let storeInfoList = {};
    try {
        storeInfoList = await get("storeList", {"keyword":keyword});
    } catch (error) {
        console.error("Fetching failed", error);
    }
    return storeInfoList;
};

export { getMapInfo, getPageInfo, getStoreDetail, addStoreInfo, getStoreList };