import React from "react";
import Header from "./components/Header/Header.js";
import Footer from "./components/Footer/Footer.js";
import MainContent from "./components/MainContent/MainContent.js";
import { SearchProvider } from "./context/searchContext.js";

function App() {
    return (
        <SearchProvider>
            <Header />
            <MainContent />
            <Footer />
        </SearchProvider>
    );
}

export default App;
