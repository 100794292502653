import React from "react";
import UseGoogleMap from "./UseGoogleMap"
import StoreInfo from "./StoreInfo"

function MainContent(){
    return (
        <div>
            <UseGoogleMap />
            <StoreInfo />
        </div>
    );
};

export default MainContent;
