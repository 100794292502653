import React, { useState, useEffect } from "react";
import { IconButton, Dialog, DialogTitle, DialogContent, Typography, Divider, Button, Stack, Checkbox, FormControlLabel, FormGroup, TextField, FormControl } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import { useSearchContext } from '../../context/searchContext';
import { label_category_japanese, label_category_western, label_category_chinese, label_category_italian_french, label_category_asian_ethnic, label_category_izakaya, label_category_international, label_category_yakiniku_teppanyaki, label_category_curry, label_category_noodles, label_category_bar, label_category_bakery, label_category_cafe_sweets, label_category_fast_food, label_category_other_cuisine, label_area_chuo, label_area_higashinada, label_area_nada, label_area_hyogo, label_area_kita, label_area_nagata, label_area_suma, label_area_tarumi, label_area_nishi, label_recommend_1, label_recommend_2, label_recommend_3, label_eatin, label_takeout, label_eatin_takeout } from "../../assets/label/label";
import { convertRecommendToNumber, convertEatinTakeoutToNumber, convertEatinTakeoutToString } from "../common/text";
import { set } from "Services/api";

function isSearchItem(searchString, key) {
    return (searchString.includes(key) || searchString.length === 0)
}

// ロードアイコン
function LoadingOverlay({ isLoading }) {
    if (!isLoading) return null;

    return (
        <div style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            zIndex: 2,
            pointerEvents: "all"
        }}>
            <CircularProgress />
        </div>
    );
}

// 検索ダイアログタイトル
function SearchTitle({ onClose }) {
    return (
        <DialogTitle>
            お店情報検索
            <IconButton
                edge="end"
                color="inherit"
                onClick={onClose}
                aria-label="close"
                sx={{ position: "absolute", right: 24, top: 12 }}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
    )
}

// 検索ダイアログコンテンツ
function SearchContents({ handleSearch, onClose }) {
    const { searchKeyword, searchCategory, searchArea, searchRecommend, searchEatinTakeout } = useSearchContext();
    // キーワードのステータス パイプをスペースに変換して追加
    const [keyword, setKeyword] = useState(searchKeyword.replace(/\|/g, " "));
    // カテゴリーのステータス
    const [categoryAll, setCategoryAll] = useState(searchCategory !== "");
    const [categoryJapanese, setCategoryJapanese] = useState(isSearchItem(searchCategory, label_category_japanese));
    const [categoryWestern, setCategoryWestern] = useState(isSearchItem(searchCategory, label_category_western));
    const [categoryChinese, setCategoryChinese] = useState(isSearchItem(searchCategory, label_category_chinese));
    const [categoryItalianFrench, setCategoryItalianFrench] = useState(isSearchItem(searchCategory, label_category_italian_french));
    const [categoryAsianEthnic, setCategoryAsianEthnic] = useState(isSearchItem(searchCategory, label_category_asian_ethnic));
    const [categoryIzakaya, setCategoryIzakaya] = useState(isSearchItem(searchCategory, label_category_izakaya));
    const [categoryInternational, setCategoryInternational] = useState(isSearchItem(searchCategory, label_category_international));
    const [categoryYakinikuTeppanyaki, setCategoryYakinikuTeppanyaki] = useState(isSearchItem(searchCategory, label_category_yakiniku_teppanyaki));
    const [categoryCurry, setCategoryCurry] = useState(isSearchItem(searchCategory, label_category_curry));
    const [categoryNoodles, setCategoryNoodles] = useState(isSearchItem(searchCategory, label_category_noodles));
    const [categoryBar, setCategoryBar] = useState(isSearchItem(searchCategory, label_category_bar));
    const [categoryBakery, setCategoryBakery] = useState(isSearchItem(searchCategory, label_category_bakery));
    const [categoryCafeSweets, setCategoryCafeSweets] = useState(isSearchItem(searchCategory, label_category_cafe_sweets));
    const [categoryFastFood, setCategoryFastFood] = useState(isSearchItem(searchCategory, label_category_fast_food));
    const [categoryOtherCuisine, setCategoryOtherCuisine] = useState(isSearchItem(searchCategory, label_category_other_cuisine));
    // 地域のステータス
    const [areaAll, setAreaAll] = useState(searchArea !== "");
    const [areaChuo, setAreaChuo] = useState(isSearchItem(searchArea, label_area_chuo));
    const [areaHigashinada, setAreaHigashinada] = useState(isSearchItem(searchArea, label_area_higashinada));
    const [areaNada, setAreaNada] = useState(isSearchItem(searchArea, label_area_nada));
    const [areaHyogo, setAreaHyogo] = useState(isSearchItem(searchArea, label_area_hyogo));
    const [areaKita, setAreaKita] = useState(isSearchItem(searchArea, label_area_kita));
    const [areaNagata, setAreaNagata] = useState(isSearchItem(searchArea, label_area_nagata));
    const [areaSuma, setAreaSuma] = useState(isSearchItem(searchArea, label_area_suma));
    const [areaTarumi, setAreaTarumi] = useState(isSearchItem(searchArea, label_area_tarumi));
    const [areaNishi, setAreaNishi] = useState(isSearchItem(searchArea, label_area_nishi));
    // おすすめ度
    const [recommendAll, setRecommendAll] = useState(searchRecommend !== "");
    const [recommend1, setRecommend1] = useState(isSearchItem(searchRecommend, "1"));
    const [recommend2, setRecommend2] = useState(isSearchItem(searchRecommend, "2"));
    const [recommend3, setRecommend3] = useState(isSearchItem(searchRecommend, "3"));
    // イートイン・テイクアウト
    const [eatinTakeoutAll, setEatinTakeoutAll] = useState(searchEatinTakeout !== "");
    const [eatin, setEatin] = useState(isSearchItem(searchEatinTakeout, "1"));
    const [takeout, setTakeout] = useState(isSearchItem(searchEatinTakeout, "2"));
    const [eatinTakeout, setEatinTakeout] = useState(isSearchItem(searchEatinTakeout, "3"));

    // カテゴリー全選択の処理
    useEffect(() => {
        if (categoryJapanese || categoryWestern || categoryChinese || categoryItalianFrench || categoryAsianEthnic || categoryIzakaya || categoryInternational || categoryYakinikuTeppanyaki || categoryCurry || categoryNoodles || categoryBar || categoryBakery || categoryCafeSweets || categoryFastFood || categoryOtherCuisine) {
            setCategoryAll(true);
        } else {
            setCategoryAll(false);
        }
    }, [categoryJapanese, categoryWestern, categoryChinese, categoryItalianFrench, categoryAsianEthnic, categoryIzakaya, categoryInternational, categoryYakinikuTeppanyaki, categoryCurry, categoryNoodles, categoryBar, categoryBakery, categoryCafeSweets, categoryFastFood, categoryOtherCuisine]);

    // 地域全選択の処理
    useEffect(() => {
        if (areaChuo || areaHigashinada || areaNada || areaHyogo || areaKita || areaNagata || areaSuma || areaTarumi || areaNishi) {
            setAreaAll(true);
        } else {
            setAreaAll(false);
        }
    }, [areaChuo, areaHigashinada, areaNada, areaHyogo, areaKita, areaNagata, areaSuma, areaTarumi, areaNishi]);

    // おすすめ度全選択の処理
    useEffect(() => {
        if (recommend1 || recommend2 || recommend3) {
            setRecommendAll(true);
        }
        else {
            setRecommendAll(false);
        }
    }, [recommend1, recommend2, recommend3]);

    // イートイン・テイクアウト全選択の処理
    useEffect(() => {
        if (eatin || takeout || eatinTakeout) {
            setEatinTakeoutAll(true);
        }
        else {
            setEatinTakeoutAll(false);
        }
    }, [eatin, takeout, eatinTakeout]);

    // キーワードリスト作成
    const makeKeywordList = () => {
        // キーワードに全角スペースまたは半角スペースが含まれている場合は、パイプ区切りに変換
        const searchKeyword = keyword.replace(/　/g, "|").replace(/ /g, "|");
        // 最初の文字、最後の文字がパイプの場合は削除
        return searchKeyword.replace(/^\||\|$/g, "");
    }

    // カテゴリーリスト作成
    const makeCategoryList = () => {
        let categoryList = [];
        let categoryString = label_category_japanese + "|" + label_category_western + "|" + label_category_chinese + "|" + label_category_italian_french + "|" + label_category_asian_ethnic + "|" + label_category_izakaya + "|" + label_category_international + "|" + label_category_yakiniku_teppanyaki + "|" + label_category_curry + "|" + label_category_noodles + "|" + label_category_bar + "|" + label_category_bakery + "|" + label_category_cafe_sweets + "|" + label_category_fast_food + "|" + label_category_other_cuisine;
        if (categoryJapanese) categoryList.push(label_category_japanese);
        if (categoryWestern) categoryList.push(label_category_western);
        if (categoryChinese) categoryList.push(label_category_chinese);
        if (categoryItalianFrench) categoryList.push(label_category_italian_french);
        if (categoryAsianEthnic) categoryList.push(label_category_asian_ethnic);
        if (categoryIzakaya) categoryList.push(label_category_izakaya);
        if (categoryInternational) categoryList.push(label_category_international);
        if (categoryYakinikuTeppanyaki) categoryList.push(label_category_yakiniku_teppanyaki);
        if (categoryCurry) categoryList.push(label_category_curry);
        if (categoryNoodles) categoryList.push(label_category_noodles);
        if (categoryBar) categoryList.push(label_category_bar);
        if (categoryBakery) categoryList.push(label_category_bakery);
        if (categoryCafeSweets) categoryList.push(label_category_cafe_sweets);
        if (categoryFastFood) categoryList.push(label_category_fast_food);
        if (categoryOtherCuisine) categoryList.push(label_category_other_cuisine);
        if (categoryList.length !== 0) {
            categoryString = categoryList.join("|");
        }
        return categoryString;
    };
    // 地域選択の処理
    const makeAreaList = () => {
        let areaList = [];
        let areaString = label_area_chuo + "|" + label_area_higashinada + "|" + label_area_nada + "|" + label_area_hyogo + "|" + label_area_kita + "|" + label_area_nagata + "|" + label_area_suma + "|" + label_area_tarumi + "|" + label_area_nishi;
        if (areaChuo) areaList.push(label_area_chuo);
        if (areaHigashinada) areaList.push(label_area_higashinada);
        if (areaNada) areaList.push(label_area_nada);
        if (areaHyogo) areaList.push(label_area_hyogo);
        if (areaKita) areaList.push(label_area_kita);
        if (areaNagata) areaList.push(label_area_nagata);
        if (areaSuma) areaList.push(label_area_suma);
        if (areaTarumi) areaList.push(label_area_tarumi);
        if (areaNishi) areaList.push(label_area_nishi);
        if (areaList.length !== 0) {
            areaString = areaList.join("|");
        }
        return areaString;
    };
    // おすすめ度選択の処理
    const makeReccomendList = () => {
        let recommendList = [];
        let recommendString = convertRecommendToNumber(label_recommend_1) + "|" + convertRecommendToNumber(label_recommend_2) + "|" + convertRecommendToNumber(label_recommend_3);
        if (recommend1) recommendList.push(convertRecommendToNumber(label_recommend_1));
        if (recommend2) recommendList.push(convertRecommendToNumber(label_recommend_2));
        if (recommend3) recommendList.push(convertRecommendToNumber(label_recommend_3));
        if (recommendList.length !== 0) {
            recommendString = recommendList.join("|");
        }
        return recommendString;
    };
    // イートイン・テイクアウト選択の処理
    const makeEatinTakeoutList = () => {
        let eatinTakeoutList = [];
        let eatinTakeoutString =  convertEatinTakeoutToNumber(label_eatin) + "|" + convertEatinTakeoutToNumber(label_takeout) + "|" + convertEatinTakeoutToNumber(label_eatin_takeout);
        if (eatin) eatinTakeoutList.push(convertEatinTakeoutToNumber(label_eatin));
        if (takeout) eatinTakeoutList.push(convertEatinTakeoutToNumber(label_takeout));
        if (eatinTakeout) eatinTakeoutList.push(convertEatinTakeoutToNumber(label_eatin_takeout));
        if (eatinTakeoutList.length !== 0) {
            eatinTakeoutString = eatinTakeoutList.join("|");
        }
        return eatinTakeoutString;
    };

    return (
        <DialogContent dividers>
            <FormControl fullWidth margin="none">
                <Typography fontWeight="bold">キーワードから探す</Typography>
                <TextField
                    variant="outlined"
                    size="small"
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                />
            </FormControl>
            <Divider variant="fullWidth" sx={{ my: 1 }} />
            {/* カテゴリー選択のグループ</FormGroup> */}
            <FormControlLabel
                control={<Checkbox checked={categoryAll}
                    onChange={() => {
                        setCategoryAll(!categoryAll);
                        setCategoryJapanese(!categoryAll);
                        setCategoryWestern(!categoryAll);
                        setCategoryChinese(!categoryAll);
                        setCategoryItalianFrench(!categoryAll);
                        setCategoryAsianEthnic(!categoryAll);
                        setCategoryIzakaya(!categoryAll);
                        setCategoryInternational(!categoryAll);
                        setCategoryYakinikuTeppanyaki(!categoryAll);
                        setCategoryCurry(!categoryAll);
                        setCategoryNoodles(!categoryAll);
                        setCategoryBar(!categoryAll);
                        setCategoryBakery(!categoryAll);
                        setCategoryCafeSweets(!categoryAll);
                        setCategoryFastFood(!categoryAll);
                        setCategoryOtherCuisine(!categoryAll);
                    }}
                />}
                label={<Typography fontWeight="bold">カテゴリー</Typography>}
            />
            <Divider variant="fullWidth" sx={{ my: 1 }} />
            <FormGroup row>
                <FormControlLabel
                    control={<Checkbox checked={categoryJapanese} onChange={() => setCategoryJapanese(!categoryJapanese)} />}
                    label={label_category_japanese}
                />
                <FormControlLabel
                    control={<Checkbox checked={categoryWestern} onChange={() => setCategoryWestern(!categoryWestern)} />}
                    label={label_category_western}
                />
                <FormControlLabel
                    control={<Checkbox checked={categoryChinese} onChange={() => setCategoryChinese(!categoryChinese)} />}
                    label={label_category_chinese}
                />
                <FormControlLabel
                    control={<Checkbox checked={categoryItalianFrench} onChange={() => setCategoryItalianFrench(!categoryItalianFrench)} />}
                    label={label_category_italian_french}
                />
                <FormControlLabel
                    control={<Checkbox checked={categoryAsianEthnic} onChange={() => setCategoryAsianEthnic(!categoryAsianEthnic)} />}
                    label={label_category_asian_ethnic}
                />
                <FormControlLabel
                    control={<Checkbox checked={categoryIzakaya} onChange={() => setCategoryIzakaya(!categoryIzakaya)} />}
                    label={label_category_izakaya}
                />
                <FormControlLabel
                    control={<Checkbox checked={categoryInternational} onChange={() => setCategoryInternational(!categoryInternational)} />}
                    label={label_category_international}
                />
                <FormControlLabel
                    control={<Checkbox checked={categoryYakinikuTeppanyaki} onChange={() => setCategoryYakinikuTeppanyaki(!categoryYakinikuTeppanyaki)} />}
                    label={label_category_yakiniku_teppanyaki}
                />
                <FormControlLabel
                    control={<Checkbox checked={categoryCurry} onChange={() => setCategoryCurry(!categoryCurry)} />}
                    label={label_category_curry}
                />
                <FormControlLabel
                    control={<Checkbox checked={categoryNoodles} onChange={() => setCategoryNoodles(!categoryNoodles)} />}
                    label={label_category_noodles}
                />
                <FormControlLabel
                    control={<Checkbox checked={categoryBar} onChange={() => setCategoryBar(!categoryBar)} />}
                    label={label_category_bar}
                />
                <FormControlLabel
                    control={<Checkbox checked={categoryBakery} onChange={() => setCategoryBakery(!categoryBakery)} />}
                    label={label_category_bakery}
                />
                <FormControlLabel
                    control={<Checkbox checked={categoryCafeSweets} onChange={() => setCategoryCafeSweets(!categoryCafeSweets)} />}
                    label={label_category_cafe_sweets}
                />
                <FormControlLabel
                    control={<Checkbox checked={categoryFastFood} onChange={() => setCategoryFastFood(!categoryFastFood)} />}
                    label={label_category_fast_food}
                />
                <FormControlLabel
                    control={<Checkbox checked={categoryOtherCuisine} onChange={() => setCategoryOtherCuisine(!categoryOtherCuisine)} />}
                    label={label_category_other_cuisine}
                />
            </FormGroup>
            <Divider variant="fullWidth" sx={{ my: 1 }} />
            {/* 地域選択のグループ */}
            <FormControlLabel
                control={<Checkbox checked={areaAll}
                    onChange={() => {
                        setAreaAll(!areaAll);
                        setAreaChuo(!areaAll);
                        setAreaHigashinada(!areaAll);
                        setAreaNada(!areaAll);
                        setAreaHyogo(!areaAll);
                        setAreaKita(!areaAll);
                        setAreaNagata(!areaAll);
                        setAreaSuma(!areaAll);
                        setAreaTarumi(!areaAll);
                        setAreaNishi(!areaAll);
                    }}
                />}
                label={<Typography fontWeight="bold">エリア</Typography>}
            />
            <Divider variant="fullWidth" sx={{ my: 1 }} />
            <FormGroup row>
                <FormControlLabel
                    control={<Checkbox checked={areaChuo} onChange={() => setAreaChuo(!areaChuo)} />}
                    label={label_area_chuo}
                />
                <FormControlLabel
                    control={<Checkbox checked={areaHigashinada} onChange={() => setAreaHigashinada(!areaHigashinada)} />}
                    label={label_area_higashinada}
                />
                <FormControlLabel
                    control={<Checkbox checked={areaNada} onChange={() => setAreaNada(!areaNada)} />}
                    label={label_area_nada}
                />
                <FormControlLabel
                    control={<Checkbox checked={areaHyogo} onChange={() => setAreaHyogo(!areaHyogo)} />}
                    label={label_area_hyogo}
                />
                <FormControlLabel
                    control={<Checkbox checked={areaKita} onChange={() => setAreaKita(!areaKita)} />}
                    label={label_area_kita}
                />
                <FormControlLabel
                    control={<Checkbox checked={areaNagata} onChange={() => setAreaNagata(!areaNagata)} />}
                    label={label_area_nagata}
                />
                <FormControlLabel
                    control={<Checkbox checked={areaSuma} onChange={() => setAreaSuma(!areaSuma)} />}
                    label={label_area_suma}
                />
                <FormControlLabel
                    control={<Checkbox checked={areaTarumi} onChange={() => setAreaTarumi(!areaTarumi)} />}
                    label={label_area_tarumi}
                />
                <FormControlLabel
                    control={<Checkbox checked={areaNishi} onChange={() => setAreaNishi(!areaNishi)} />}
                    label={label_area_nishi}
                />
            </FormGroup>
            <Divider variant="fullWidth" sx={{ my: 1 }} />
            {/* おすすめ度選択のグループ */}
            <FormControlLabel
                control={<Checkbox checked={recommendAll}
                    onChange={() => {
                        setRecommendAll(!recommendAll);
                        setRecommend1(!recommendAll);
                        setRecommend2(!recommendAll);
                        setRecommend3(!recommendAll);
                    }}
                />}
                label={<Typography fontWeight="bold">おすすめ度</Typography>}
            />
            <Divider variant="fullWidth" sx={{ my: 1 }} />
            <FormGroup row>
                <FormControlLabel
                    control={<Checkbox checked={recommend1} onChange={() => setRecommend1(!recommend1)} />}
                    label={label_recommend_1}
                />
                <FormControlLabel
                    control={<Checkbox checked={recommend2} onChange={() => setRecommend2(!recommend2)} />}
                    label={label_recommend_2}
                />
                <FormControlLabel
                    control={<Checkbox checked={recommend3} onChange={() => setRecommend3(!recommend3)} />}
                    label={label_recommend_3}
                />
            </FormGroup>
            <Divider variant="fullWidth" sx={{ my: 1 }} />
            {/* イートイン・テイクアウト選択のグループ */}
            <FormControlLabel
                control={<Checkbox checked={eatinTakeoutAll}
                    onChange={() => {
                        setEatinTakeoutAll(!eatinTakeoutAll);
                        setEatin(!eatinTakeoutAll);
                        setTakeout(!eatinTakeoutAll);
                        setEatinTakeout(!eatinTakeoutAll);
                    }}
                />}
                label={<Typography fontWeight="bold">イートイン・テイクアウト</Typography>}
            />
            <Divider variant="fullWidth" sx={{ my: 1 }} />
            <FormGroup row>
                <FormControlLabel
                    control={<Checkbox checked={eatin} onChange={() => setEatin(!eatin)} />}
                    label={label_eatin}
                />
                <FormControlLabel
                    control={<Checkbox checked={takeout} onChange={() => setTakeout(!takeout)} />}
                    label={label_takeout}
                />
                <FormControlLabel
                    control={<Checkbox checked={eatinTakeout} onChange={() => setEatinTakeout(!eatinTakeout)} />}
                    label={label_eatin_takeout}
                />
            </FormGroup>
            <Divider variant="fullWidth" sx={{ my: 1 }} />
            <Stack direction="row" spacing={5} sx={{ justifyContent: "center", mt: 2 }}>
                <Button variant="contained" size="large" color="primary" onClick={() => handleSearch(makeKeywordList(), makeCategoryList(), makeAreaList(), makeReccomendList(), makeEatinTakeoutList())} sx={{ width: 100 }} >検索</Button>
                <Button variant="contained" size="large" color="primary" onClick={onClose} sx={{ width: 100 }}>閉じる</Button>
            </Stack>
        </DialogContent>
    )
}

// メイン処理
function SearchScreen({ open, onClose }) {
    // コンテキストから検索関数を取得
    const { setSearchKeyword, setSearchCategory, setSearchArea, setSearchRecommend, setSearchEatinTakeout, setCurrentPage } = useSearchContext();
    // 検索待ちステータス
    const [isLoading, setIsLoading] = useState(false);

    // 「検索」ボタンのイベントハンドラ
    const handleSearch = async (keyword, categoryList, areaList, recommendList, eatinTakeoutList) => {
        // ローディング開始
        setIsLoading(true);
        // 検索条件を設定
        setSearchKeyword(keyword);
        setSearchCategory(categoryList);
        setSearchArea(areaList);
        setSearchRecommend(recommendList);
        setSearchEatinTakeout(eatinTakeoutList);
        setCurrentPage(1);
        // ローディング終了
        setIsLoading(false);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            {/* ローディングアイコン */}
            <LoadingOverlay isLoading={isLoading}/>
            {/* ダイアログのタイトル */}
            <SearchTitle onClose={onClose}/>
            {/* ダイアログの内容 */}
            <SearchContents handleSearch={handleSearch} onClose={onClose}/>
        </Dialog>
    );
}

export default SearchScreen;
