import React, { useState, useEffect, useCallback, useContext } from "react";
import { Card, CardContent, CardMedia, CardActions, Button, Typography, Grid, Divider, Pagination, Box } from "@mui/material";
import DetailScreen from "./DetailScreen";
import { getPageInfo } from "../../Services/StoreService";
import { useSearchContext } from "../../context/searchContext";
import NoImage from "../../assets/images/NoImage.webp"
import { convertRecommendToString } from "../common/text";

// カード情報
function InfoCard({ store, onDetailButtonClick }) {
    // コメントのスタイル(5行表示でそれ以上は"..."で表示される)
    const commentStyle = {
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 5,
        overflow: "hidden",
        textOverflow: "ellipsis",
        height: "7.5em",
        lineHeight: "1.5em",
    };
    // 画像がない場合はNoImageを表示
    const showImage = store.image ? store.image : NoImage;

    return (
        <Grid item xs={12} sm={6} md={4}>
            <Card sx={{m: 1, height: "100%"}}>
                <CardContent sx={{"&:last-child": {paddingBottom: "0px"}}}>
                    <Typography variant="h6" component="div">
                        {store.name}
                    </Typography>
                    <Divider variant="fullWidth" sx={{ my: 1 }} />
                    <CardMedia component="img" height="180" image={showImage}  alt="店舗写真" />
                    <Divider variant="fullWidth" sx={{ my: 1 }} />
                    <Typography variant="body2" color="text.secondary">
                        住所：{store.address}
                    </Typography>
                    <Divider variant="fullWidth" sx={{ my: 1 }} />
                    <Typography variant="body2" color="text.secondary">
                        カテゴリ：{store.category}
                    </Typography>
                    <Divider variant="fullWidth" sx={{ my: 1 }} />
                    <Typography variant="body2" color="text.secondary">
                        おすすめ度：{convertRecommendToString(store.recommend)}
                    </Typography>
                    <Divider variant="fullWidth" sx={{ my: 1 }} />
                    <Typography variant="body2" color="text.secondary" sx={commentStyle}>
                        {store.comment}
                    </Typography>
                    <Divider variant="fullWidth" sx={{ my: 1 }} />
                    <CardActions sx={{ justifyContent: "center" }}>
                        <Button variant="contained" size="large" onClick={onDetailButtonClick} sx={{ width: 100 }}>詳細</Button>
                    </CardActions>
                </CardContent>
            </Card>
        </Grid>
    );
}

// メイン処理
function StoreInfo() {
    // 選択した店情報
    const [selectedStore, setSelectedStore] = useState(null);
    // 詳細画面表示情報
    const [isDetailVisible, setIsDetailVisible] = useState(false);
    const clickDetailButton = useCallback((store) => {
        setSelectedStore(store);
        setIsDetailVisible(true);
    }, []);
    const clickDetailCloseButton = useCallback(() => {
        setSelectedStore(null);
        setIsDetailVisible(false);
    }, []);

    // 検索条件
    const {searchKeyword, searchCategory, searchArea, searchRecommend, searchEatinTakeout, currentPage, setCurrentPage, totalPages, setTotalPages} = useSearchContext();

    // お店情報取得用ステート
    const [pageInfo, setPageInfo] = useState(null);
    const [loading, setLoading] = useState(true);

    // ページ切り替え関数
    const handleChangePage = async (_, newPage) => {
        setCurrentPage(newPage);
    };

    // ページ情報更新
    useEffect(() => {
        const fetchPageInfo = async () => {
            setLoading(true);
            const data = await getPageInfo(searchKeyword, searchCategory, searchArea, searchRecommend, searchEatinTakeout, currentPage);
            setPageInfo(data.page_info); 
            setTotalPages(data.total_page);
            setLoading(false);
        };    
        fetchPageInfo();
    }, [currentPage, searchKeyword, searchCategory, searchArea, searchRecommend, searchEatinTakeout, setTotalPages]);

    // ローディング中、エラー発生時、データがない場合の表示
    if (loading) {
        return <div>Loading...</div>;
    }
    if (!pageInfo) {
        return <div>No data available</div>;
    }

    return (
        <div>
            <Grid container spacing={2} sx={{ p: 2 }}>
                {pageInfo.map((store, index) => (
                    <InfoCard key={index} store={store} onDetailButtonClick={() => clickDetailButton(store)} />
                ))}
                {isDetailVisible && (
                    <DetailScreen id={selectedStore.id} onClose={clickDetailCloseButton} />
                )}
            </Grid>
            <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                <Pagination 
                    count={totalPages} 
                    page={currentPage} 
                    onChange={handleChangePage} 
                    color="primary"
                />
            </Box>
        </div>
    );
}

export default StoreInfo;
