import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { LoadScript } from "@react-google-maps/api";
import App from './App';
import AddShop from './components/MainContent/AddShop';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Router>
		<LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API}>
			<Routes>
				<Route path={"/"} element={<App />} />
				<Route path={"/addshop"} element={<AddShop />} />
			</Routes>
		</LoadScript>
	</Router>
);
