import React, { useState } from "react";
import { AppBar, Toolbar, Typography, IconButton, Avatar } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import icon from "assets/images/icon.png";
import SearchScreen from "../MainContent/SearchScreen";

function Header() {
    // ダイアログのオープン情報
    const [isSearchDialogVisible, setSearchDialogOpen] = useState(false);

    const handleSearchDialogOpen = () => {
        setSearchDialogOpen(true);
    };

    const handleSearchDialogClose = () => {
        setSearchDialogOpen(false);
    };

    // ダイアログが閉じられた時用の保持情報
    const [formData, setFormData] = useState({});

    return (
        <AppBar position="static" component="header">
            <Toolbar>
                <IconButton edge="start" color="inherit" aria-label="icon" href="https://twitter.com/KobeCharmDel">
                    <Avatar alt="Remy Sharp" src={icon} />
                </IconButton>
                <Typography variant="h7" component="div" sx={{ flexGrow: 1 }}>
                    神戸グルメマップ
                </Typography>
                {/* 検索ボタン */}
                <IconButton color="inherit" aria-label="search" onClick={handleSearchDialogOpen}>
                    <SearchIcon />
                </IconButton>
            </Toolbar>
            {isSearchDialogVisible && (
                <SearchScreen 
                    open={isSearchDialogVisible} 
                    onClose={handleSearchDialogClose} 
                />
            )}
        </AppBar>
    );
}

export default Header;
