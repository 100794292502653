import React from "react";
import { Container, Typography, Box, Avatar, Link } from "@mui/material";
import contact from "assets/images/contact.webp";

function Footer() {
    return (
        <Container maxWidth="lg" component="footer" sx={{ borderTop: "2px solid #ddd", mt: 4, pt: 2, pb: 2 }}>
            <Typography variant="body2" align="right" sx={{ mb: 1 }}>
                &copy; かずき
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                <Link href="https://twitter.com/PlentyTimeKobe" target="_blank" rel="noopener noreferrer" sx={{ display: "flex", alignItems: "center", textDecoration: "none", color: "black"}}>
                    <Typography variant="body2">
                        問い合わせ先：
                    </Typography>
                    <Avatar src={contact} sx={{ width: 24, height: 24, mr: 1 }} />
                </Link>
            </Box>
        </Container>
    );
}

export default Footer;
