import { label_recommend_1, label_recommend_2, label_recommend_3, label_eatin, label_takeout, label_eatin_takeout } from  "../../assets/label/label.js";

// 文字列フォーマット
export const format = (str, ...args) => {
    for (const [i, arg] of args.entries()) {
        const regExp = new RegExp(`\\{${i}\\}`, 'g')
        str = str.replace(regExp, arg)
    }
    return str
}

// おすすめ度の文字列を数字に変換
export const convertRecommendToNumber = (str) => {
    let num = 0;
    switch (str) {
        case label_recommend_1:
            num = 1;
            break;
        case label_recommend_2:
            num = 2;
            break;
        case label_recommend_3:
            num = 3;
            break;
        default:
            break;
    }
    return num;
}

// おすすめ度の数字を文字列に変換
export const convertRecommendToString = (num) => {
    let str = "";
    switch (num) {
        case 1:
            str = label_recommend_1;
            break;
        case 2:
            str = label_recommend_2;
            break;
        case 3:
            str = label_recommend_3;
            break;
        default:
            break;
    }
    return str;
}

// イートイン・テイクアウトの文字列を数字に変換
export const convertEatinTakeoutToNumber = (str) => {
    let num = 0;
    switch (str) {
        case label_eatin:
            num = 1;
            break;
        case label_takeout:
            num = 2;
            break;
        case label_eatin_takeout:
            num = 3;
            break;
        default:
            break;
    }
    return num;
}

// イートイン・テイクアウトの数字を文字列に変換
export const convertEatinTakeoutToString = (num) => {
    let str = "";
    switch (num) {
        case 1:
            str = label_eatin;
            break;
        case 2:
            str = label_takeout;
            break;
        case 3:
            str = label_eatin_takeout;
            break;
        default:
            break;
    }
    return str;
}
