import React, { useState, useEffect } from 'react';
import { TextField, Divider, Button, Select, MenuItem, FormControl, InputLabel, Stack, Input, Box, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { addStoreInfo, getStoreList } from "../../Services/StoreService";
import { convertToWebP } from "../common/image";
import { getCoordinates } from "../common/map";
import { label_category_japanese, label_category_western, label_category_chinese, label_category_italian_french, label_category_asian_ethnic, label_category_izakaya, label_category_international, label_category_yakiniku_teppanyaki, label_category_curry, label_category_noodles, label_category_bar, label_category_bakery, label_category_cafe_sweets, label_category_fast_food, label_category_other_cuisine, label_area_chuo, label_area_higashinada, label_area_nada, label_area_hyogo, label_area_kita, label_area_nagata, label_area_suma, label_area_tarumi, label_area_nishi, label_recommend_1, label_recommend_2, label_recommend_3, label_eatin, label_takeout, label_eatin_takeout } from "../../assets/label/label";

// 追加処理
const addFormData = async (setIsLoading, formData) => {
    let result = false;

    // ローディング開始
    setIsLoading(true);

    const addData = new FormData();
    // 緯度経度を取得
    const {lat, lng} = await getCoordinates(formData.address);
    if((lat !== -1) || (lng !== -1)) {
        // フォームフィールドの値を追加
        if(formData.id !== "") {
            addData.append("id", formData.id);
        }
        addData.append("name", formData.name);
        addData.append("category", formData.category);
        addData.append("recommend", formData.recommend);
        addData.append("product", formData.product);
        addData.append("area", formData.area);
        addData.append("address", formData.address);
        addData.append("lat", String(lat));
        addData.append("lng", String(lng));
        addData.append("eatin_takeout", formData.eatin_takeout);
        if (!("url" in formData)) {
            formData.url = "";
        }
        addData.append("url", formData.url || "");
        if (!("comment" in formData)) {
            formData.comment = "";
        }
        addData.append("comment", formData.comment || "");
        // 画像ファイルを追加
        if (formData.images) {
            for (const image of formData.images) {
                const filename = image.name.split(".").slice(0, -1).join(".");
                const webpImage = await convertToWebP(image);
                addData.append("images", webpImage, filename +".webp");
            }
        }
        result = await addStoreInfo(addData);
        if(result) {
            // 成功した場合
            alert("お店情報の登録に成功しました！")
            // 画面をリロード
            window.location.reload()
        } else {
            // 失敗した場合
            alert("お店情報の登録に失敗しました。\nもう一度やり直してください。");
        }
    } else {
        // 緯度経度が取得できなかった場合
        alert("住所が認識できませんでした。\n内容を見直してください。");
    }

    // ローディング終了
    setIsLoading(false);

    return result;
}

// ロードアイコン
function LoadingOverlay({ isLoading }) {
    if (!isLoading) return null;

    return (
        <div style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            zIndex: 2,
            pointerEvents: "all"
        }}>
            <CircularProgress />
        </div>
    );
}

// 追加フォームコンテンツ
function AddForm( {setIsLoading} ) {
    // 入力値
    const [inputData, setInputData] = useState({
        id: '',
        name: '',
        category: '',
        recommend: '',
        product: '',
        area: '',
        address: '',
        eatin_takeout: '',
        url: '',
        comment: '',
        images: []
    });
    // 検索結果を表示するテキスト
    const [searchResult, setSearchResult] = useState('');

    // コンポーネントがマウントされたときにローカルストレージからデータを読み込む
    useEffect(() => {
        const savedInputs = localStorage.getItem('userInputs');
        if (savedInputs) {
            const parsedInputs = JSON.parse(savedInputs);
            // 画像データを除いた状態でStateを復元
            const { images, ...restInputs } = parsedInputs;
            setInputData(restInputs);
        }
    }, []);

    // 入力値が変わるたびにローカルストレージに保存
    useEffect(() => {
        const { images, ...restInputs } = inputData;
        localStorage.setItem('userInputs', JSON.stringify(restInputs));
    }, [inputData]);

    // フォームデータ
    const handleChange = (e) => {
        const name = e.target.name;
        if (e.target.files) {
            const files = Array.from(e.target.files).slice(0, 5);
            setInputData(prev => ({ ...prev, [name]: files }));
        } else {
            setInputData(prev => ({ ...prev, [name]: e.target.value }));
        }
    };

    // 検索ボタン
    const handleSearch = async () => {
        // 店舗情報を取得
        const storeList = await getStoreList(inputData.name);
        // お店情報が空でなければ検索結果を表示
        let storeNames = '';
        const shopNum = Object.keys(storeList).length;
        if(shopNum === 1) {
            // 1件の場合は詳細を表示
            setInputData({
                id: storeList[0].id,
                name: storeList[0].name,
                category: storeList[0].category,
                recommend: storeList[0].recommend,
                product: storeList[0].product,
                area: storeList[0].area,
                address: storeList[0].address,
                eatin_takeout: storeList[0].eatin_takeout,
                url: storeList[0].url,
                comment: storeList[0].comment,
                images: []
            });
            storeNames = storeList[0].id + ": " + storeList[0].name;
        }
        else if(shopNum  > 1) {
            // 2件以上の場合は一覧を表示
            storeNames = storeList.map(store => store.name).join('\n');
            setInputData({ id: '', name: '', category: '', recommend: '', product: '', area: '', address: '', eatin_takeout: '', url: '', comment: '', images: [] });
        } else {
            // 0件の場合は該当なしを表示
            storeNames = '該当なし';
            setInputData({ id: '', name: '', category: '', recommend: '', product: '', area: '', address: '', eatin_takeout: '', url: '', comment: '', images: [] });
        }
        setSearchResult(storeNames);
    };

    // 選択肢リスト
    const categoryList = [label_category_japanese, label_category_western, label_category_chinese, label_category_italian_french, label_category_asian_ethnic, label_category_izakaya, label_category_international, label_category_yakiniku_teppanyaki, label_category_curry, label_category_noodles, label_category_bar, label_category_bakery, label_category_cafe_sweets, label_category_fast_food, label_category_other_cuisine];
    const areaList = [label_area_chuo, label_area_higashinada, label_area_nada, label_area_hyogo, label_area_kita, label_area_nagata, label_area_suma, label_area_tarumi, label_area_nishi];
    const recommendList = [label_recommend_1, label_recommend_2, label_recommend_3];
    const eatinTakeoutList = [label_eatin, label_takeout, label_eatin_takeout];

    // 必須項目が入力できているか確認
    const isFormValid = () => {
        let isUrl = false;
        if(inputData.url) {
            const pattern = /^(https?:\/\/)/;
            isUrl = pattern.test(inputData.url);    
        }
        return inputData.name && inputData.category && inputData.recommend && inputData.product && inputData.area && inputData.address && inputData.eatin_takeout && inputData.comment && isUrl;
    };
    
    // フォームデータを追加
    const handleAddFormData = async () => {
        const result = await addFormData(setIsLoading, inputData);
        if(result) {
            // フォームデータをリセット
            setInputData({ id: '', name: '', category: '', recommend: '', product: '', area: '', address: '', eatin_takeout: '', url: '', comment: '', images: [] });
        }
    };
    
    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "flex-start", minHeight: "100vh" }}>
           <div style={{ margin: "10px", maxWidth: "500px", width: "100%" }}>
                <h2>お店情報追加</h2>
                <Box sx={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "16px" }}>
                    <TextField
                        name="name"
                        margin="dense"
                        label="お店の名前"
                        type="text"
                        fullWidth
                        required
                        value={inputData.name || ""}
                        onChange={handleChange}
                    />
                    <Button variant="contained" color="primary" onClick={handleSearch}>
                        検索
                    </Button>
                </Box>
                <Box sx={{ marginBottom: "16px" }}>
                {searchResult && <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>{searchResult}</Typography>}
                </Box>
                <FormControl fullWidth margin="dense">
                    <InputLabel>カテゴリー</InputLabel>
                    <Select
                        name="category"
                        labelId="category-label"
                        value={inputData.category || ""}
                        label="カテゴリー"
                        onChange={handleChange}
                        required
                    >
                        {categoryList.map((prefecture, index) => (
                            <MenuItem key={index} value={prefecture}>{prefecture}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="dense">
                    <InputLabel>おすすめ度</InputLabel>
                    <Select
                        name="recommend"
                        labelId="recommend-label"
                        value={inputData.recommend || ""}
                        label="おすすめ度"
                        onChange={handleChange}
                        required
                    >
                        {recommendList.map((prefecture, index) => (
                            <MenuItem key={index} value={String(index+1)}>{prefecture}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField name="product" margin="dense" label="おすすめ商品" type="text" fullWidth required value={inputData.product || ""} onChange={handleChange} />
                <FormControl fullWidth margin="dense">
                    <InputLabel>エリア</InputLabel>
                    <Select
                        name="area"
                        labelId="area-label"
                        value={inputData.area || ""}
                        label="エリア"
                        onChange={handleChange}
                        required
                    >
                        {areaList.map((prefecture, index) => (
                            <MenuItem key={index} value={prefecture}>{prefecture}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField name="address" margin="dense" label="住所" type="text" fullWidth required value={inputData.address || ""} onChange={handleChange} />
                <FormControl fullWidth margin="dense">
                    <InputLabel>イートイン＆テイクアウト</InputLabel>
                    <Select
                        name="eatin_takeout"
                        labelId="eatin_takeout-label"
                        value={inputData.eatin_takeout || ""}
                        label="イートイン＆テイクアウト"
                        onChange={handleChange}
                        required
                    >
                        {eatinTakeoutList.map((prefecture, index) => (
                            <MenuItem key={index} value={String(index+1)}>{prefecture}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField name="url" margin="dense" label="お店のURL" type="url" fullWidth required value={inputData.url || ""} onChange={handleChange} />
                <TextField name="comment" margin="dense" label="コメント" type="text" fullWidth required multiline rows={4} value={inputData.comment || ""} onChange={handleChange} />
                <FormControl fullWidth margin="dense">
                    画像アップロード（最大5枚）
                    <Input
                        name="images"
                        type="file"
                        inputProps={{ 
                            multiple: true,
                            accept: "image/*"
                        }}
                        onChange={handleChange}
                    />
                </FormControl>                
                {inputData.images && inputData.images.map((image, index) => (
                    <img 
                        key={index} 
                        src={URL.createObjectURL(image)} 
                        alt={`Preview ${index}`} 
                        style={{ maxWidth: "150px", maxHeight: "150px", margin: "5px" }}
                    />
                ))}
                <Divider variant="fullWidth" sx={{ my: 1 }} />
                <Stack direction="row" spacing={5} sx={{ justifyContent: "center", mt: 2 }}>
                    <Button variant="contained" size="large" color="primary" onClick={handleAddFormData} sx={{ width: 100 }} disabled={!isFormValid()}>追加</Button>
                </Stack>
            </div>
        </div>
    )
}

// メイン処理
function AddShop() {
    // 追加待ちステータス
    const [isLoading, setIsLoading] = useState(false);

    return (
        <div>
            {/* ローディングアイコン */}
            <LoadingOverlay isLoading={isLoading}/>
            {/* 追加情報 */}
            <AddForm setIsLoading={setIsLoading} />
        </div>
    );
}

export default AddShop;
