import markerAsianEthnic from "../../assets/images/mark_asian_ethnic.png"
import markerBakery from "../../assets/images/mark_bakery.png";
import markerBar from "../../assets/images/mark_bar.png";
import markerCafeSweets from "../../assets/images/mark_cafe_sweets.png";
import markerChinese from "../../assets/images/mark_chinese.png";
import markerCurry from "../../assets/images/mark_curry.png";
import markerFastFood from "../../assets/images/mark_fast_food.png";
import markerInternational from "../../assets/images/mark_international.png";
import markerItalianFrench from "../../assets/images/mark_italian_french.png";
import markerIzakaya from "../../assets/images/mark_izakaya.png";
import markerJapanese from "../../assets/images/mark_japanese.png";
import markerNoodles from "../../assets/images/mark_noodles.png";
import markerOtherCuisine from "../../assets/images/mark_other_cuisine.png";
import markerWestern from "../../assets/images/mark_western.png";
import markerYakinikuTeppanyaki from "../../assets/images/mark_yakiniku_teppanyaki.png";
import { label_category_japanese, label_category_western, label_category_chinese, label_category_italian_french, label_category_asian_ethnic, label_category_izakaya, label_category_international, label_category_yakiniku_teppanyaki, label_category_curry, label_category_noodles, label_category_bar, label_category_bakery, label_category_cafe_sweets, label_category_fast_food, label_category_other_cuisine } from "../../assets/label/label";

// 緯度・経度を取得
export const getCoordinates = async (address) => {
    const geocoder = new window.google.maps.Geocoder();
    let lat = -1, lng = -1;

    try {
        const result = await new Promise((resolve, reject) => {
            geocoder.geocode({ address: address }, (response, status) => {
                if (status === "OK") {
                    resolve(response);
                } else {
                    reject("Geocode was not successful for the following reason: " + status);
                }
            });
        });
        lat = result[0].geometry.location.lat();
        lng = result[0].geometry.location.lng();
    } catch(error) {
        console.error(error);
    }
    return {lat, lng};
}

// マーカー画像の取得
export const getMarkerIcon = (category) => {
    let marker = markerOtherCuisine;
    switch (category) {
        case label_category_asian_ethnic:
            marker = markerAsianEthnic;
            break;
        case label_category_bakery:
            marker = markerBakery;
            break;
        case label_category_bar:
            marker = markerBar;
            break;
        case label_category_cafe_sweets:
            marker = markerCafeSweets;
            break;
        case label_category_chinese:
            marker = markerChinese;
            break;
        case label_category_curry:
            marker = markerCurry;
            break;
        case label_category_fast_food:
            marker = markerFastFood;
            break;
        case label_category_international:
            marker = markerInternational;
            break;
        case label_category_italian_french:
            marker = markerItalianFrench;
            break;
        case label_category_izakaya:
            marker = markerIzakaya;
            break;
        case label_category_japanese:
            marker = markerJapanese;
            break;
        case label_category_noodles:
            marker = markerNoodles;
            break;
        case label_category_western:
            marker = markerWestern;
            break;
        case label_category_yakiniku_teppanyaki:
            marker = markerYakinikuTeppanyaki;
            break;
        default:
            marker = markerOtherCuisine;
            break;
    }
    return marker;
};
