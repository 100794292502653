// カテゴリ
export const label_category_japanese = "和食";
export const label_category_western = "洋食";
export const label_category_chinese = "中華";
export const label_category_italian_french = "イタリアン・フレンチ";
export const label_category_asian_ethnic = "アジア・エスニック";
export const label_category_izakaya = "居酒屋";
export const label_category_international = "各国料理";
export const label_category_yakiniku_teppanyaki = "焼肉・鉄板焼き";
export const label_category_curry = "カレー";
export const label_category_noodles = "麺類";
export const label_category_bar = "バー";
export const label_category_bakery = "ベーカリー";
export const label_category_cafe_sweets = "カフェ・スイーツ";
export const label_category_fast_food = "ファーストフード";
export const label_category_other_cuisine = "その他の料理";

// エリア
export const label_area_chuo = "中央区";
export const label_area_higashinada = "東灘区";
export const label_area_nada = "灘区";
export const label_area_hyogo = "兵庫区";
export const label_area_kita = "北区";
export const label_area_nagata = "長田区";
export const label_area_suma = "須磨区";
export const label_area_tarumi = "垂水区";
export const label_area_nishi = "西区";

// おすすめ度
export const label_recommend_1 = "おすすめ";
export const label_recommend_2 = "ほどよくおすすめ";
export const label_recommend_3 = "超おすすめ";

// イートイン・テイクアウト
export const label_eatin = "イートイン";
export const label_takeout = "テイクアウト";
export const label_eatin_takeout = "イートイン＆テイクアウト";