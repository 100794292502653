import React, { createContext, useContext, useState } from 'react';
import { label_category_japanese, label_category_western, label_category_chinese, label_category_italian_french, label_category_asian_ethnic, label_category_izakaya, label_category_international, label_category_yakiniku_teppanyaki, label_category_curry, label_category_noodles, label_category_bar, label_category_bakery, label_category_cafe_sweets, label_category_fast_food, label_category_other_cuisine, label_area_chuo, label_area_higashinada, label_area_nada, label_area_hyogo, label_area_kita, label_area_nagata, label_area_suma, label_area_tarumi, label_area_nishi, label_recommend_1, label_recommend_2, label_recommend_3, label_eatin, label_takeout, label_eatin_takeout } from  "../assets/label/label";
import { convertRecommendToNumber, convertEatinTakeoutToNumber } from "../components/common/text";

const SearchContext = createContext();
export const useSearchContext = () => useContext(SearchContext);

export const SearchProvider = ({ children }) => {
	// 検索条件のステート
	const [searchKeyword, setSearchKeyword] = useState("");
	const [searchCategory, setSearchCategory] = useState(label_category_japanese + "|" + label_category_western + "|" + label_category_chinese + "|" + label_category_italian_french + "|" + label_category_asian_ethnic + "|" + label_category_izakaya + "|" + label_category_international + "|" + label_category_yakiniku_teppanyaki + "|" + label_category_curry + "|" + label_category_noodles + "|" + label_category_bar + "|" + label_category_bakery + "|" + label_category_cafe_sweets + "|" + label_category_fast_food + "|" + label_category_other_cuisine);
	const [searchArea, setSearchArea] = useState(label_area_chuo + "|" + label_area_higashinada + "|" + label_area_nada + "|" + label_area_hyogo + "|" + label_area_kita + "|" + label_area_nagata + "|" + label_area_suma + "|" + label_area_tarumi + "|" + label_area_nishi);
	const [searchRecommend, setSearchRecommend] = useState(convertRecommendToNumber(label_recommend_1) + "|" + convertRecommendToNumber(label_recommend_2) + "|" + convertRecommendToNumber(label_recommend_3));
	const [searchEatinTakeout, setSearchEatinTakeout] = useState(convertEatinTakeoutToNumber(label_eatin) + "|" + convertEatinTakeoutToNumber(label_takeout) + "|" + convertEatinTakeoutToNumber(label_eatin_takeout));
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);

	return (
		<SearchContext.Provider 
			value = {{ 
				searchKeyword, setSearchKeyword,
				searchCategory, setSearchCategory,
				searchArea, setSearchArea,
				searchRecommend, setSearchRecommend,
				searchEatinTakeout, setSearchEatinTakeout,
				currentPage, setCurrentPage,
				totalPages, setTotalPages
			}}
		>
			{children}
		</SearchContext.Provider>
	);
};
