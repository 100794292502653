// 入力された画像が特定の解像度を超える場合にリサイズしてからwebpに変更
export const convertToWebP = (imageFile, maxResolution = 16e6) => {
    return new Promise((resolve, reject) => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();

        img.onload = () => {
        let width = img.width;
        let height = img.height;

        // 解像度が最大値を超えている場合にリサイズ
        if (width * height > maxResolution) {
            const aspectRatio = width / height;
            const newWidth = Math.sqrt(maxResolution * aspectRatio);
            const newHeight = maxResolution / newWidth;
            width = newWidth;
            height = newHeight;
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        canvas.toBlob((blob) => {
            resolve(blob);
        }, "image/webp", 0.8); // ここで品質を設定
        };

        img.onerror = (error) => {
        reject(error);
        };

        img.src = URL.createObjectURL(imageFile);
    });
};