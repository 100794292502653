import React, { useState, useEffect } from "react";
import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import DetailScreen from "./DetailScreen";
import { getMapInfo } from "../../Services/StoreService";
import { useSearchContext } from "../../context/searchContext";
import { getMarkerIcon } from "../common/map";

function UseGoogleMap() {
    // マップのスタイル
    const containerStyle = {
        width: "100%",
        height: "400px"
    };

    // マップの吹き出しのスタイル
    const MapInfoWindow = styled("div")({
        maxWidth: "250px",
        padding: "8px",
        boxSizing: "border-box",
        overflow: "hidden"
    });
      
    // 吹き出しのタイトルのスタイル
    const MapTitle = styled("div")({
        fontWeight: "bold",
        marginBottom: "8px",
        color: "blue",
        textDecoration: "underline",
        cursor: "pointer"
    });

    // マップの中心位置
    const [center, setCenter] = useState({ lat: 34.6939082, lng: 135.193093 });

    // 選択されたマーカーの店情報
    const [selectedMapInfo, setSelectedMapInfo] = useState(null);
    const clickHandleMarker = (mapInfo) => {
        setSelectedMapInfo(mapInfo);
    };
    const closeInfoWindow = () => {
        setSelectedMapInfo(null);
    };

    // 詳細画面表示情報
    const [isDetailVisible, setIsDetailVisible] = useState(false);
    const openDetailScreen = () => {
        setIsDetailVisible(true);
    };
    const closeDetailScreen = () => {
        setIsDetailVisible(false);
    };

    // マップ情報取得用ステート
    const [mapInfos, setMapInfo] = useState(null);
    const [loading, setLoading] = useState(true);

    // 検索条件
    const {searchKeyword, searchCategory, searchArea, searchRecommend, searchEatinTakeout} = useSearchContext();

    // コンポーネントのマウント時に非同期データを取得
    useEffect(() => {
        const fetchMapInfo = async () => {
            setLoading(true);
            const data = await getMapInfo(searchKeyword, searchCategory, searchArea, searchRecommend, searchEatinTakeout);
            // マップ情報更新
            setMapInfo(data);
            setLoading(false);
        };    
        fetchMapInfo();
    }, [searchKeyword, searchCategory, searchArea, searchRecommend, searchEatinTakeout]);

    // ローディング中、エラー発生時、データがない場合の表示
    if (loading) {
        return <div>Loading...</div>;
    }
    if (!mapInfos) {
        return <div>No data available</div>;
    }

    // マップロード時の関数
    const handleLoad = (map) => {
        const updateCenter = () => {
            const newCenter = map.getCenter();
            setCenter({ lat: newCenter.lat(), lng: newCenter.lng() });
        };
        // マップドラッグ・ズームで中心位置を更新
        map.addListener("dragend", updateCenter);
        map.addListener("zoom_changed", updateCenter);
        // アンマウントで更新関数を削除
        return () => {
            map.removeListener("dragend", updateCenter);
            map.removeListener("zoom_changed", updateCenter);
        };
    };
    
    return (
        <Box id="map">
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={15}
                onLoad={handleLoad}
                onClick={closeInfoWindow}
            >
                {mapInfos.map((mapInfo, index) => (
                    <Marker 
                        key={index} 
                        position={{ lat: parseFloat(mapInfo.lat), lng: parseFloat(mapInfo.lng) }} 
                        onClick={() => clickHandleMarker(mapInfo)}
                        icon={{
                            url: getMarkerIcon(mapInfo.category),
                            scaledSize: new window.google.maps.Size(32, 32), 
                        }}
                    >
                        {selectedMapInfo && selectedMapInfo === mapInfo && (
                            <InfoWindow
                                position={{ lat: parseFloat(selectedMapInfo.lat), lng: parseFloat(selectedMapInfo.lng) }}
                                onCloseClick={closeInfoWindow}
                            >
                                <MapInfoWindow>
                                    <MapTitle onClick={openDetailScreen}>
                                        {selectedMapInfo.name}
                                    </MapTitle>
                                    {/* マップに画像を表示したい時、Flask側も修正する必要がある */}
                                    {/* <MapImage src={selectedMapInfo.image[0]} alt={`${selectedMapInfo.name} 写真`} /> */}
                                </MapInfoWindow>
                            </InfoWindow>
                        )}
                    </Marker>
                ))}
            </GoogleMap>
            {isDetailVisible && (
                <DetailScreen id={selectedMapInfo.id} onClose={closeDetailScreen} />
            )}
        </Box>
    );
}

export default UseGoogleMap;
  